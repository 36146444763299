.info {
  $s: &;

  .wrap {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 75px 30px;
    max-width: 100%;

    &:nth-child(1) {
      background-color: $green;

      path {
        fill: $light-green;
      }
    }

    &:nth-child(2) {
      background-color: $white;

      path {
        fill: $light-green;
      }

      #{$s}__title {
        color: $green;
      }

      #{$s}__description {
        color: $green;
      }
    }

    &:nth-child(3) {
      background-color: $light-green;

      path {
        fill: $green;
      }

      #{$s}__title {
        color: $green;
      }

      #{$s}__description {
        color: $green;
      }

      #{$s}__link {
        color: $green;
        border-color: $green;

        &:hover {
          background-color: $green;
          color: $white;
        }
      }
    }
  }

  &__icon-area {
    svg {
      width: 32px;
      height: 32px;
    }

    img {
      width: 32px;
      height: 32px;
    }

  }

  &__title {
    margin-top: 12px;
    color: $white;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.2;
  }

  &__description {
    color: $white;
    margin-top: 4px;
    line-height: 1.3;
  }

  &__link {
    margin-top: 16px;
    display: inline-block;
    color: $white;
    text-decoration: none;
    border: 2px solid $light-green;
    border-radius: 5px;
    padding: 10px;
    transition: .4s;
    color: $light-green;
    font-weight: 500;

    &:hover {
      background-color: $light-green;
      color: $green;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .info {
    $s: &;

    .wrap {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__item {
      width: 33.3%;
      justify-content: center;
      aspect-ratio: 1/1;
    }

    &__button-area {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &__link {
      padding: 10px 20px;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .info {
    $s: &;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 50%;
      background-color: $green;
      z-index: -1;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 50%;
      background-color: $light-green;
      z-index: -1;
    }

    &__item {
      padding: 68px 30px;
    }

    &__button-area {
      flex: inherit;
    }
  }
}