.about {
  padding: 32px 0;
  &__title {
    font-weight: 500;
    color: $green;
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 12px;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__text-area{
    margin-bottom: 32px;
  }

  &__gallery{
    position: relative;
    margin-bottom: 40px;
  }

  &__pagination {
    position: absolute;
    width: 100%;
    top: calc(100% + 16px);
    display: flex;
    justify-content: center;
  }

  &__arrow {
    position: relative;
    background-color: $green;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: calc(50% + 3px);
      top: 50%;
      transform: translate(-50%, -50%) rotate(135deg);
      width: 14px;
      height: 14px;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
    }

    &-right {
      &::after {
        left: calc(50% - 3px);
        transform: translate(-50%, -50%) rotate(-45deg);

      }
    }
  }

  p{
    line-height: 1.5;
    margin-bottom: 8px;
  }

  a{
    color: $green;
    text-decoration: none;
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .about {
    &__title {
      font-size: 28px;
      text-align: left;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .about {
    .wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__title {
      font-size: 28px;
      text-align: left;
    }

    &__text-area{
      margin-top: 24px;
      width: 52%;
    }

    &__pagination {
      top: 50%;
      transform: translateY(-50%);
      justify-content: space-between;
      z-index: 99;
    }

    &__gallery{
      margin-top: 24px;
      width: 44%;
    }
  }
}