.testimony {
  background-color: rgba($light-green, .1);
  padding: 64px 0;

  &__items {
    position: relative;
    padding-top: 100px;
  }

  &__pagination {
    position: absolute;
    width: 100%;
    top: 20px;
    display: flex;
    justify-content: center;
  }

  &__arrow {
    position: relative;
    background-color: $green;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 10px;

    &::after {
      content: '';
      position: absolute;
      left: calc(50% + 3px);
      top: 50%;
      transform: translate(-50%, -50%) rotate(135deg);
      width: 14px;
      height: 14px;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
    }

    &-right {
      &::after {
        left: calc(50% - 3px);
        transform: translate(-50%, -50%) rotate(-45deg);

      }
    }
  }

  &__item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  &__image-area {
    width: 180px;
    height: 285px;
    overflow: hidden;
    border-radius: 10px;
  }

  &__content {
    margin-top: 12px;
  }

  &__text {
    text-align: center;
    line-height: 1.2;
  }

  &__author {
    display: block;
    text-align: center;
    margin-top: 8px;
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .testimony {
  
    &__arrow {
      cursor: pointer;
      transition: .4s;

      &:hover {
        background-color: lighten($green, 6%);
      }
    }

    &__item {
      padding: 0 20px;
      justify-content: space-between;
    }


  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .testimony {
    &__item {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__content {
      width: calc(100% - 200px);
    }

    &__text {
      text-align: left;
      line-height: 1.6;
      font-size: 18px;
    }

    &__author {
      text-align: left;
    }
  }
}