.main-banner {
  &__item {
    height: 540px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-top: 20px;
  }

  &__img{
    position: absolute;
    object-fit: cover;
    width: 100%;
    top: 0;
    height: 540px;
  }

  &__title {
    color: $white;
    font-weight: 700;
    font-size: 32px;
  }

  &__description {
    margin-top: 16px;
    color: $white;
    line-height: 1.6;

    strong {
      font-weight: 600;
    }
  }

  &__button {
    background-color: $light-green;
    color: $green;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 16px;
    font-weight: 600;
  }

  &__pagination {
    position: absolute;
    width: 100%;
    max-width: 1190px;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;
  }

  &__arrow {
    position: relative;
    background-color: $light-green;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: calc(50% + 3px);
      top: 50%;
      transform: translate(-50%, -50%) rotate(135deg);
      width: 14px;
      height: 14px;
      border-right: 2px solid $green;
      border-bottom: 2px solid $green;
    }

    &-right {
      &::after {
        left: calc(50% - 3px);
        transform: translate(-50%, -50%) rotate(-45deg);

      }
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .main-banner {

    .wrap{
      padding-left: 100px;
    }
    
    &__item {
      height: 540px;
      justify-content: center;
    }

    &__title {
      font-size: 40px;
      line-height: 1.2;
      max-width: 420px;
    }

    &__description {
      max-width: 420px;
      font-size: 16px;
    }

    &__button {
      transition: .4s;

      &:hover {
        transform: scale(1.05);
      }
    }

    &__pagination {
      bottom: inherit;
      top: 50%;
      transform: translate(-50%, -50%);
      justify-content: space-between;
    }

  }
}