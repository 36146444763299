*,
body,
textarea {
  font-family: $font-family-primary;
}

body {
  &.stop-scroll {
    overflow-y: hidden;
  }
}

img {
  max-width: 100%;
}

strong {
  font-weight: 700;
}

.container {
  position: relative;
  width: 100%;
  max-width: $container;
  margin: 0 auto;
  padding: 0 15px;
}

.light-background {
  background-color: rgba($light-gray, .4);
}

.whatsapp{
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 10000;
  transition: 0.3s all;
  img{
    width: 76px;
  }
  &:hover{
    transform: translateY(-10px);
  }
}

@media(min-width: #{$container}) {
  .container {
    padding: 0px;
  }
}