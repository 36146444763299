.call-vaccination-schedule {
  padding-top: 24px;
  padding-bottom: 48px;
  background-color: $light-green;

  &__button {
    display: block;
    margin-top: 12px;
    color: $white;
    background-color: $green;
    text-align: center;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 500;
  }

  p {
    line-height: 1.3;
    text-align: center;
    font-size: 18px;
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .call-vaccination-schedule {
    .wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__button {
      line-height: 1.3;
    }

    p {
      text-align: left;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .call-vaccination-schedule {
    padding-bottom: 24px;

    .wrap {
      flex-wrap: wrap;
    }

    &__illustration {
      max-width: 40%;
    }

    &__text {
      max-width: 58%;
    }

    &__button{
      display: inline-block;
    }
  }
}