.faq {
  $s: &;
  padding: 64px 0;
  background-color: $light-gray;

  &__list {
    margin-top: 24px;
  }

  &__item {
    cursor: pointer;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: $green;
    border: 1px solid $green;
    padding: 15px 20px;
    padding-right: 50px;
    /* margin-bottom: 12px; */
    transition: 0.4s;

    &::after {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 8px;
      height: 8px;
      border-right: 2px solid $green;
      border-bottom: 2px solid $green;
      transition: 0.4s;
    }

    #{$s}__item.active &,
    #{$s}__item:hover & {
      background-color: $green;
      color: $light-green;
    }

    #{$s}__item.active & {
      &::after {
        border-top: 2px solid $light-green;
        border-left: 2px solid $light-green;
      }
    }

    #{$s}__item:hover & {
      &::after {
        border-color: $light-green;
      }
    }
  }

  &__content {
    border: 1px solid #fff;
    border-top: 0;
    padding: 0px 15px;
    max-height: 0;
    opacity: 0;
    transition: 0.4s;
    pointer-events: none;

    #{$s}__item.active & {
      max-height: 1000px;
      padding: 15px;
      opacity: 1;
      pointer-events: all;
    }
    &--border-dark{
      border: 1px solid #05413f;
    }
  }

  &__vacina{
      display: flex;
      align-items: center;
      gap: 10px;
      color: black;
      &:hover{
        color: $light-green;
      }
  }

  p {
    line-height: 1.6;
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .faq {

    .wrap {
      max-width: #{map-get($breakpoints, 'md')}px;
    }
  }
}