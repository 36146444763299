/*WP-COREALIGN*/
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

img.alignnone {
    margin: 5px 20px 20px 0;
}

img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}