.main-footer {
  background-color: $green;
  padding: 64px 0;

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    max-width: 220px;
    display: none;
  }

  &__social {
    margin-top: 32px;

    &-icon {
      display: inline;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      img {
        max-width: 32px;
      }
    }
  }

  &__nav-area {
    margin-top: 32px;
  }

  &__nav-title {
    text-align: center;
    font-weight: 800;
    color: $white;
    font-size: 22px;
  }

  &__nav {
    margin-top: 12px;
  }

  &__nav-item {
    text-decoration: none;
    text-align: center;
    margin-bottom: 8px;
    color: $white;
    font-weight: 500;
    line-height: 1.3;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $white;
      font-weight: 500;
      line-height: 1.3;
    }
  }
}

.copyright {
  background-color: $light-green;
  padding: 15px 0;
  text-align: center;

  p {
    color: $white;
    font-weight: 500;
    line-height: 1.4;
  }

  a {
    color: $green;
  }

  &__gh {
    margin-top: 15px;
    font-size: 14px;

    a {
      font-weight: 600;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .main-footer {

    .wrap {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap
    }

  

    &__about {
      width: 100%;
    }

    &__nav-area {
      width: 50%;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .main-footer {

    .wrap {
      justify-content: space-between;
    }

    &__logo{
      display: block;
    }

    &__about {
      width: 15%;
      margin-right: 2%;
    }

    &__logo {
      width: 100%;
    }

    &__social-icon {

      img{
        transition: .4s;
  
        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &__nav-area {
      width: auto;
      max-width: 20%;
    }

    &__nav-title {
      text-align: left;
      font-size: 18px;
      line-height: 1.4;
    }

    &__nav-item {
      text-align: left;
      font-size: 14px;

      a {
        transition: .4s;

        &:hover {
          color: $light-green;
        }
      }
    }
  }

  .copyright {
    padding: 15px 0;
    text-align: left;

    .wrap {
      display: flex;
      justify-content: space-between;
    }

    p {
      font-size: 14px;
    }


    &__gh {
      margin-top: 0px;
    }
  }
}