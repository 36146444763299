.content {
  padding: 32px 0;

  &__thumbnail {
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;

    figcaption {
      font-size: 12px;
    }

    img {
      display: inline-block;
      width: 100%;
    }
  }

  &__search {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 24px;
    border: 1px solid $green;
    border-radius: 5px;

    p {
      font-weight: 500;
      text-align: left;
    }
  }

  &__form {
    position: relative;
    width: 100%;

    p{
      line-height: 1.3;
      margin-bottom: 8px;
      color: $green;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #05413f;
      outline-color: #a6ca44;
      padding-right: 35px;
    }

    button {
      background-color: transparent;
      position: absolute;
      right: 3px;
      bottom: 7px;
      border: none;
      border-left: 1px solid $green;
      width: 30px;
      cursor: pointer;

      img {
        margin-bottom: 0;
      }
    }
  }

  &__sidebar {
    margin-top: 24px;

    &-call-vaccine {
      margin-top: 24px;
      padding: 24px;
      background-color: $light-green;

      p {
        line-height: 1.3;
        text-align: center;
        font-size: 18px;
      }
    }

    &-button {
      display: block;
      margin-top: 12px;
      color: $white;
      background-color: $green;
      text-align: center;
      padding: 10px 15px;
      border-radius: 5px;
      font-weight: 500;
    }
  }

  &__posts{
    margin-top: 24px;

    &-item{
      display: block;
      margin-top: 24px;
    }
  }

  &__author {
    background: $light-gray;
    padding: 24px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    &-image {
      img {
        width: 120px;
        border-radius: 50%;
      }
    }

    &-name {
      font-weight: 600;
      color: $green;
      font-size: 18px;
      text-align: center;
    }

    &-description {
      text-align: center;
    }
  }

  &__the-content {
    @for $index from 1 through 6 {
      h#{$index} {
        font-weight: 600;
        color: $green;
        line-height: 1.3;
        margin-bottom: 8px;
      }
    }

    h1 {
      font-size: 30px;
    }

    h2 {
      margin-top: 16px;
      font-size: 28px;
    }

    h3 {
      margin-top: 16px;
      font-size: 26px;
    }

    h4 {
      margin-top: 16px;
      font-size: 24px;
    }

    h5 {
      margin-top: 16px;
      font-size: 22px;
    }

    h6 {
      margin-top: 16px;
      font-size: 20px;
    }

    p {
      line-height: 1.5;
      margin-bottom: 8px;

      a {
        color: $green;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    img {
      margin-bottom: 8px;
    }

    ul,
    ul {
      margin-bottom: 8px;
      list-style: disc;
      list-style-position: inside;

      li {
        line-height: 1.5;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .content {
    &__sidebar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;

      &-button {
        line-height: 1.3;
      }

      &-call-vaccine{
        display: flex;

        p {
          text-align: left;
        }
      }

    }

    &__search {
      width: 100%;
    }

    &__author {
      flex-direction: row;
      flex-wrap: wrap;

      &-image {
        width: 120px;
      }

      &-info {
        margin-left: 24px;
        width: calc(100% - 144px);
        box-sizing: border-box;
      }

      &-name {
        text-align: left;
      }

      &-description {
        text-align: left;
      }
    }

    &__posts{  
      &-items{
        display: flex;
        justify-content: space-between;
      }

      &-item{
        width: 32%;
      }
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .content {
    .wrap {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__the-content {
      width: 73%;
    }

    &__sidebar {
      position: sticky;
      top: 20px;
      width: 25%;
      flex-direction: column;
      box-sizing: border-box;

      &-button {
        line-height: 1.3;
      }

      &-call-vaccine{
        display: flex;
        flex-direction: column;
      }
    }

    &__posts{
      .header-section__title{
        font-size: 22px;
      }

      &-items{
        flex-direction: column;
      }

      &-item{
        width: 100%;
      }
    }
  }
}