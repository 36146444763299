.certificate-of-bravery {
  position: relative;
  padding: 32px 0;

  &__items {
    margin-top: 24px;
  }

  &__item {
    padding: 20px;
  }

  &__pagination {
    position: absolute;
    width: 100%;
    max-width: 1190px;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;
    z-index: 100;
  }

  &__arrow {
    position: relative;
    background-color: $light-green;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: calc(50% + 3px);
      top: 50%;
      transform: translate(-50%, -50%) rotate(135deg);
      width: 10px;
      height: 10px;
      border-right: 2px solid $green;
      border-bottom: 2px solid $green;
    }

    &-right {
      &::after {
        left: calc(50% - 3px);
        transform: translate(-50%, -50%) rotate(-45deg);

      }
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .certificate-of-bravery {
    &__pagination {
      bottom: inherit;
      top: 50%;
      transform: translate(-50%, -50%);
      justify-content: space-between;
    }

    &__arrow {
      width: 50px;
      height: 50px;

      &::after {
        width: 14px;
        height: 14px;
      }
    }
  }
}