.contact.contato{
  padding-bottom: 0;
}
.contact {
  padding: 64px 0;

  &__title {
    font-weight: 500;
    color: $green;
    font-size: 22px;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 12px;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__pag{
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    margin: 0 auto;
    gap: 20px;
  }

  &__link {
    position: relative;

    &:before {
      content: url('../images/link.svg');
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__social-icon {
    margin: 0 10px;
    width: 32px;
  }

  &__form-area {
    margin-top: 24px;
  }

  &__field-area {
    width: 100%;
    margin-bottom: 12px;
  }

  &__field:not([type="checkbox"]) {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $green;
    outline-color: $light-green;
  }

  &__helper{
    font-size: 14px;
    cursor: pointer;
  }

  &__button {
    width: 100%;
    background-color: $green;
    color: $light-green;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: .4s;
    height: 52px;
    font-size: 16px;

    &:hover{
      background-color: $light-green;
      color: $green;
    }
  }

  &__technical{
    justify-content: center;
    display: flex;
    text-align: center;
    gap: 20px;
    padding-top: 13px;
    flex-wrap: wrap;

    &-item{
      display: block;

      p{
        text-align: center  !important;
      }
    }
    
    &-img{
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid $green;
    }
  }

  form {
    margin-top: 24px;
  }

  p {
    text-align: center;
    display: block;
    color: $green;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  &__whatsapp{
    background-color: #16c439;
    display: inline-flex !important;
    align-items: center !important;
    font-weight: bold !important;
    border-radius: 5px !important;
    padding: 10px 25px !important;
    color: #ffffff !important;
    text-transform: uppercase !important;
    font-size: 16px !important;
    gap: 10px;
  }

  a {
    text-align: center;
    display: block;
    color: $green;
    line-height: 1.3;
    margin-bottom: 8px;
  }

}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .contact {
    .wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__pag{
      grid-template-columns: 1fr 1fr;
    }

    &__infos{
      width: 48%;
    }

    &__title {
      font-size: 28px;
      text-align: left;
    }

    &__social{
      justify-content: flex-start;
    }

    &__social-icon{
      img{
        transition: .4s;
  
        &:hover {
          transform: scale(1.1);
        }
      }
      &:first-child{
        margin-left: 0;
      }
    }

    &__technical{
      justify-content: start;
    }

    &__form-area{
      margin-top: 0;
      width: 48%;
    }

    a{
      text-align: left;

      &:hover{
        font-weight: 500;
      }
    }

    p{
      text-align: left;
    }
  }
}