.breadcrumbs {
  padding-top: 18px;

  &__spacer {
    margin: 0 5px;
  }

  a{
    font-size: 14px;
    font-weight: 600;
    color: $green;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: $green;
  }
}

@media(max-width:576px){
  .breadcrumbs{
    padding-bottom: 30px;
    text-align: center;
  }
}