.card-post {
  background-color: $light-gray;

  &__image-area {
    overflow: hidden;
    height: 45vw;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  
    img{
      transition: .7s;
    }
  }

  &__content-area {
    padding: 15px 20px;
  }

  &__title {
    color: $green;
    font-weight: 500;
    line-height: 1.3;
    margin-top: 8px;
  }

  &__type {
    color: $green;
    font-weight: 500;
    line-height: 1.3;
    margin-top: 10px;
    font-weight: bold;
    font-size: 15px;
  }

  &__date {
    color: $green;
    font-size: 12px;
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .card-post {
    $s: &;
    transition: .4s;

    &:hover {
      background-color: darken($light-gray, 6%);
    }

    &__image-area {
      height: 16vw;

      #{$s}:hover & {
        img {
          transform: scale(1.03);
        }
      }
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'xl')}px) {
  .card-post {
    &__image-area {
      height: 195px;
    }
  }
}