.technical-team {
  padding: 64px 0;
  background-color: $light-gray;

  &__title {
    color: $green;
    font-size: 22px;
    line-height: 1.3;
    text-align: center;
  }

  &__list {
    margin-top: 12px;
  }

  &__member {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:first-child) {
      margin-top: 24px;
    }

    &-photo {
      border-radius: 5px;
      width: 260px;
      object-fit: cover;
      height: 260px;
    }

    &-name {
      font-size: 20px;
      text-align: center;
      font-weight: 500;
      color: $green;
      margin-top: 8px;
      line-height: 1.3;
    }

    &-occupation {
      margin-top: 4px;
      font-size: 18px;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .technical-team {
    &__title {
      font-size: 28px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__member {
      width: 30%;

      &:first-child {
        margin-top: 24px;
      }

      &:nth-child(3n+2) {
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}