.header-section {
  &--center {
    text-align: center;
  }

  &__title {
    font-size: 26px;
    color: $green;
    font-weight: 700;
    line-height: 1.2;
  }

  &__description {
    margin-top: 8px;
    line-height: 1.3;
    
    a{
      font-weight: 600;
      color: $green;
    }
  }

  &__link {
    display: block;
    margin-top: 8px;
    font-weight: 500;
    color: $green;
  }

  &--vacines-search{
    padding-top: 40px;
  }

}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .header-section {
    $s: &;

    &--link {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--center {
      max-width: 768px;
      margin: 0 auto;
    }

    &__title {
      font-size: 32px;
    }

    &__link {
      margin-left: 12px;
      transition: .4s;

      &:hover {
        color: $light-green;
      }
    }
  }
}