.text-image {
  padding: 32px 0;

  &__list {
    margin-top: 12px;
  }

  &__item {
    margin-top: 24px;
  }

  &__title {
    font-size: 20px;
    color: $green;
    line-height: 1.3;
  }

  &__image {
    margin-top: 12px;
  }

  &__button {
    background-color: $light-green;
    text-decoration: none;
    color: $green;
    font-weight: 600;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 12px;
    transition: .5s;

    &:hover {
      background-color: darken($light-green, 10%);
    }
  }

  p {
    margin-top: 8px;
    line-height: 1.4;

    a {
      color: $green;
      font-weight: 500;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .text-image__item.impar{
      flex-direction: inherit !important;
  }
  .text-image {
    $s: &;
    padding: 64px 0;
    display: flex;
    flex-direction: column;

    &__list {
      &--reverse {
        #{$s}__item {
          &:nth-child(even) {
            flex-direction: row-reverse;
          }
          &:nth-child(odd) {
            flex-direction: row;
          }
        }
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }
    }

    &__text {
      width: 48%;
    }

    &__title {
      font-size: 26px;
    }

    &__image {
      width: 48%;
    }
  }
}