.service-area{
    padding: 32px 0 70px 0;


    &__sub{
        color: $green;
        padding-top: 10px;
        line-height: 1.2;
        a{
            text-decoration: underline;
            color: $green;
            font-weight: bold;
        }
        p{
            margin-bottom: 1rem;
        }
        h2{
            font-size: 1.7rem;
            color: $green;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        h3{
            font-size: 1.5rem;
            color: $green;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        h4{
            font-size: 1.3rem;
        }
    }
    &__img{
        text-align: center;
        padding-top: 50px;
        iframe{
            width: 80%;
        }
    }
}

.address{
    padding: 70px 0 70px 0;
    background-color: #f8f8f8;
    &__items{
        display: flex;
        gap: 30px;
        padding-top: 32px;
    }

    &__item{
        position: relative;
        padding: 20px 20px 20px 75px;
        border-bottom: 1px solid $green;

        &-colum{
            width: 50%;
        }
    }

    &__pin{
        position: absolute;
        left: 10px;
        top: 18px;
        width: 50px;
        text-align: center;
    }

    &__name{
        padding: 10px 0 13px 0;
        font-size: 22px;
    }

    &__link{
        text-decoration: underline;
        color: $green;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
    }

    &__map{
        &-colum{
            width: 50%;
        }
    }

    &__search-box{
        input{
            font-size: 20px;
            line-height: 24px;
            width: 100%;
            padding: 10px 20px;
            border: 1px solid $green;
            box-sizing: border-box;
            padding-left: 54px;
            font-size: 16px;
            margin-bottom: 30px;
            &::placeholder{
                color: #999999;
            }
        }
        position: relative;
        
        svg{
            position: absolute;
            left: 19px;
            top: 11px;
        }
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
    }
      
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
    td b{
        font-weight: 700;
    }
}

.address-cta{
    padding: 70px 0 70px 0;
    background-color: #f8f8f8;
    text-align: center;
}

.address-numbers{
    padding: 70px 0 70px 0;
    &__list{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 32px;
    }
    &__item{
        width: 25%;
        text-align: center;
        border: 2px solid #f8f8f8;
        padding: 35px 15px;
        &:nth-child(even){
            background-color: #f8f8f8;
        }
    }
    &__title{
        color: $green;
        font-size: 1.125rem;
        line-height: 1.3;
        text-align: center;
        font-weight: 600;
        margin-top: 8px;
    }
    &__desc{
        margin-top: 8px;
        color: $green;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 8px;
    }
    &__link{
        color: $green;
        font-weight: 600;
    }
}

.faq{
    &__address{
        background-color: transparent;
    }
}

@media(max-width:556px) {
    .address{
        &__items{
            display: block;
        }

        &__map{
            padding-top: 25px   ;
            &-colum{
                width: 100%;
            }
        }

        &__item{
            &-colum{
                width: 100%;
            }
        }
    }

    .address-numbers{
        &__list{
            display: block;
        }
        &__item{
            width: 100%;
        }
    }

    .service-area{
        &__img{
            iframe{
                width: 100%;
            }
        }
    }
}


@media(max-width:996px) {
    .address{
        &__map{
            iframe{
                width: 100%;
                height: 300px;
            }
        }
    }
}

