.post-listing {
  $s: &;
  padding: 64px 0;

  &__categories {
    // margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &-item {
      width: 80%;
      background-color: $light-gray;
      color: $green;
      padding: 10px 20px;
      border-radius: 24px;
      margin-bottom: 12px;
      text-align: center;
      font-weight: 500;
    }
  }
  

  &__search {
    margin-top: 24px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 250px;

    input {
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #05413f;
      outline-color: #a6ca44;

      padding-right: 35px;
    }

    button {
      background-color: transparent;
      position: absolute;
      right: 3px;
      border: none;
      border-left: 1px solid $green;
      width: 30px;
      cursor: pointer;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    &--vacines{
      padding-bottom: 50px;
      margin-top: 0;
    }
  }

  &__item {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  &--multiples {
    #{$s}__item {
      margin-bottom: 20px;
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;

    span,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #fff;
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border-radius: 2px;
      border: 1px solid $green;
      background-color: $green;
      transition: all 0.5s ease-in-out;
      font-weight: 500;

      &.current,
      &:hover {
        border: 1px solid $green;
        background-color: rgba($green, 0.2);
        color: $green;
      }
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .post-listing {
    &__categories {
      flex-wrap: wrap;
      justify-content: center;

      &-item {
        width: auto;
        margin-right: 10px;
        margin-left: 10px;
        transition: .4s;

        &:hover {
          background-color: darken($light-gray, 10%);
        }
      }
    }

    &__items {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__item {
      width: 32%;
      margin-top: 0;

      &:nth-child(3n+2) {
        margin-left: 2%;
        margin-right: 2%;
      }
    }
  }
}