.units {
  padding-top: 64px;

  &--padding-top-bottom{
  padding: 64px 40px 0 0;
  }
  .wrap {
    padding: 0;
  }

  &__title {
    color: $green;
    font-size: 22px;
    line-height: 1.3;
    text-align: center;
  }

  &__name {
    color: $green;
    font-size: 18px;
    line-height: 1.3;
    text-align: center;
    font-weight: 600;
  }

  &__item {
    // margin-top: 24px;
    padding: 32px;

    &:nth-child(even) {
      background-color: $light-gray;
    }
  }

  &__address {
    position: relative;

    &:before {
      content: url('../images/pin.svg');
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  &__phone {
    position: relative;

    &:before {
      content: url('../images/tel.svg');
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  &__whatsapp {
    position: relative;
    font-weight: bold;

    &:before {
      content: url('../images/whatsapp.svg');
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  &__opening-hours {
    position: relative;

    &:before {
      content: url('../images/clock.svg');
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  &__address{
    text-decoration: underline;
  }

  p {
    margin-top: 8px;
    color: $green;
    line-height: 1.3;
    text-align: center;

    a {
      color: $green;

    }
  }

  &__link{
    background-color: $green;
    color: #ffffff;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: .4s;
    margin-top: 24px;
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .units {
    &__title {
      font-size: 28px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 32px;
    }

    &__item {
      width: 50%;

      &:nth-child(2),
      &:nth-child(3) {
        background-color: $light-gray;
      }

      &:nth-child(4) {
        background-color: transparent;
      }
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .units {
    &__item {
      width: 25%;
      border: 2px solid $light-gray;
      padding: 35px 15px;

      &:nth-child(2),
      &:nth-child(3) {
        background-color: transparent;
      }

      &:nth-child(4) {
        background-color: transparent;
      }

      &:nth-child(even) {
        background-color: $light-gray;
      }
    }
  }
}