.benefits {
  padding: 48px 0;

  &__item {
    text-align: center;
    margin-top: 24px;

    &-icon {
      img {
        width: 128px;
      }
    }

    &-description {
      line-height: 1.3;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .benefits {
    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__item {
      width: 48%;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .benefits {
    padding: 64px 0;

    &__list {
      justify-content: space-evenly;
    }

    &__item {
      width: 19%;
    }
  }

}