.vaccines {
  padding: 45px 0;

  &--list {
    display: none;
  }

  &-section {
    &--description {
      text-align: justify;
      margin: 15px;

      ul{
        list-style: disc;
        list-style-position: inside;
      }

      p,
      li {
        margin-bottom: 8px;
      }

      span {
        display: block;
        margin-bottom: 1rem;
      }
    }
  }

  &__categories {
    margin-top: 12px;
    display: block;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;

    &-item {
      position: relative;
      height: 120px;
      background-color: $light-gray;
      color: $green;
      margin: 0 auto;
      padding: 10px 20px;
      width: 120px;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: 12px;
      font-weight: 500;
      overflow: hidden;
      display: flex;
      align-items: center;
      line-height: 1.3;

      span {
        max-width: 68%;
      }

      img {
        bottom: 0;
        right: 0;
        width: 80px;
      }
    }
  }

  &__profile {
    text-align: center;
    margin-top: 5px;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  &__list {
    margin-top: 24px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:first-child) {
      margin-top: 24px;
    }

    &-name {
      margin-top: 8px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
    }

    &-description {
      margin-top: 20px;
      text-align: center;
      line-height: 1.3;
    }

    &-recommendation {
      margin-top: 8px;
      text-align: center;
      line-height: 1.3;
    }

    &-button-areas {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-button {
      display: inline-block;
      color: $white;
      padding: 10px 20px;
      border-radius: 24px;
      background-color: $light-green;
      font-weight: 500;

      &--transparent {
        color: $green;
        background: transparent;
        border: 1px solid $green;
      }

      &:not(:nth-child(1)) {
        margin-top: 12px;
      }
    }

    a:not(.vaccines__item-button) {
      color: $green;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .vaccines {
    &__categories {
      display: flex;
      margin-top: 24px;
      justify-content: center;

      &-item {
        margin: 0 auto;
        transition: .4s;

        &:hover {
          background-color: darken($light-gray, 10%);
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      width: 48%;

      &:nth-child(1) {
        margin-top: 24px;
      }

      &:nth-child(odd) {
        margin-right: 4%;
      }

      &-button {
        &--transparent {
          transition: .4s;

          &:hover {
            background-color: $green;
            color: $white;
          }
        }
      }

    }
  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .vaccines {
    &--list {
      display: block;
    }

    &__item {
      width: 30%;

      &:not(:first-child) {
        margin-top: 36px;
      }

      &:nth-child(1) {
        margin-top: 36px;
      }

      &:nth-child(odd) {
        margin-right: inherit;
      }

      &:nth-child(3n+2) {
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}