.the-vaccine {
  padding: 32px 0;

  .wrap {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 26px;
    color: $green;
    font-weight: 700;
    line-height: 1.2;
  }

  &__description {
    margin-top: 12px;

    p {
      line-height: 1.5;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a{
      color: $green;
      text-decoration: underline;
    }
  }

  &__information {
    margin-top: 24px;

    &-item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 18px;
    }

    &-icon {
      width: 10%;
      text-align: center;

      span {
        font-weight: 600;
        font-size: 26px;
        color: $green;
      }
    }

    &-text {
      width: 90%;
    }

    &-title {
      font-weight: 500;
      font-size: 22px;
      color: $green;
    }

    &-description {
      margin-top: 8px;
      line-height: 1.3;
      margin-bottom: 8px;
    }
  }

  &__sidebar {
    padding: 24px;
    padding-bottom: 48px;
    background-color: $light-green;

    &-button {
      display: block;
      margin-top: 12px;
      color: $white;
      background-color: $green;
      text-align: center;
      padding: 10px 15px;
      border-radius: 5px;
      font-weight: 500;
    }

    p {
      line-height: 1.3;
      text-align: center;
      font-size: 18px;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .the-vaccine {
    padding: 64px 0;

    &__title {
      font-size: 32px;
    }

    &__information {
      &-icon {
        width: 5%;
      }

      &-text {
        width: 95%;
      }
    }

    &__sidebar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-button {
        line-height: 1.3;
      }

      p {
        text-align: left;
      }
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .the-vaccine {
    .wrap {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__left {
      width: 73%;
    }

    &__sidebar {
      position: sticky;
      top: 20px;
      width: 25%;
      flex-direction: column;
      box-sizing: border-box;

      &-button {
        line-height: 1.3;
      }

      p {
        text-align: left;
      }
    }
  }
}