.modal-search{
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 101; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */

    &__content{
        margin: auto;
        padding: 20px;
        width: 50%;
    }

    form{
        position: relative;
    }

    &__close{
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;

        top: 50px;  
        right: 80px;
        position: absolute;
        font-size: 50px;
    }

    &__search{
        width: 100%;
        font-size: 30px;
        border: none;
        border-bottom: 3px solid rgba(255, 255, 255, 0.938);
        background: 0 0;
        color: #fff;
        padding-bottom: 12px;
        outline: none;

        &::placeholder
        {
            color: #ffffff;
        }
    }

    form{
        position: relative;
    }

    &__icon{
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
    }
}

@media(max-width:576px){
    .modal-search{
        &__content{
            width: 100%;
        }
    }
}

@media(max-width:1269px){
    .modal-search{
        &__close{
            top: 150px;
            right: 22px;
        }
    }
}