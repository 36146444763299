.pre-header {
  padding: 15px;
  background-color: $red;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    font-weight: 600;
    color: $white;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;
  }

  &__icon {
    display: none;
  }
}

.main-header {
  $s: &;
  background-color: $light-gray;
  z-index: 100;

  .wrap {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &__logo-area {
    max-width: 150px;
  }

  &__nav-area {
    background-color: $light-gray;
    position: absolute;
    left: 0;
    top: 100%;
    max-height: 0;
    width: 100%;
    z-index: 90;
    transition: .4s;
    overflow: hidden;
    padding: 0 15px;

    #{$s}.menu-active & {
      max-height: 550px;
      padding: 15px;
    }
  }

  &__nav-item {
    position: relative;
    margin-bottom: 12px;
    text-align: center;

    a {
      font-weight: 500;
      color: $green;
    }
  }

  &__has-child>a {
    position: relative;

    &::after {
      content: '';
      margin-left: 5px;
      display: inline-block;
      transform: rotate(45deg) translateY(-5px);
      width: 7px;
      height: 7px;
      border-right: 2px solid $green;
      border-bottom: 2px solid $green;
      transition: .4s;
    }
  }

  &__has-child.open {
    #{$s}__submenu {
      padding: 10px;
      max-height: 500px;
    }
  }

  &__has-child.open>a {
    &::after {
      transform: rotate(225deg) translateY(0px);
    }
  }

  &__submenu {
    margin-top: 5px;
    padding: 0 10px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: .4s;

    a {
      font-weight: 400;
    }

    #{$s}__nav-item:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    width: 100%;
    background-color: $green;
    color: $white;
    font-weight: 500;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    display: block;
    text-align: center;
  }

  &__hamburguer {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 40px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 100;
  }

  &__bars {
    position: relative;
    display: block;
    width: 100%;
    height: 3px;
    background-color: $green;
    transition: 0.4s;

    &::before {
      content: "";
      transform-origin: left;
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $green;
      transition: 0.4s;
    }

    &::after {
      content: "";
      transform-origin: left;
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $green;
      transition: 0.4s;
    }

    &--active {
      background-color: transparent;

      &::before {
        top: -15px;
        transform: rotate(45deg);
        width: calc(100% + 3px);
      }

      &::after {
        bottom: -15px;
        transform: rotate(-45deg);
        width: calc(100% + 3px);
      }
    }
  }

  &__search{
    cursor: pointer;
    position: absolute;
    right: 79px;
    top: 40%;
  }
}

@media(min-width: #{map-get($breakpoints, 'md')}px) {
  .pre-header {
    &__icon {
      display: block;
      margin-right: 10px;
    }
  }
  
}

@media(min-width: #{map-get($breakpoints, 'lg')}px) {
  .pre-header {
    $s: &;

    a {
      transition: .4s;

      &:hover {
        color: darken($white, 5%);

        #{$s}__icon {
          transform: translateX(5px) scale(1.1);
        }
      }
    }

    &__icon {
      display: block;
      margin-right: 10px;
      transition: .4s;
    }
  }

  .main-header {
    $s: &;

    .wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__nav-area {
      background-color: transparent;
      position: relative;
      left: inherit;
      top: inherit;
      max-height: inherit;
      width: auto;
      z-index: inherit;
      transition: inherit;
      overflow: inherit;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      #{$s}.menu-active & {
        max-height: inherit;
        padding: 0;
      }
    }

    &__search{
      position: unset;
    }

    &__nav-item {
      $navItem: &;
      margin-bottom: 0;
      display: inline;
      margin-right: 25px;

      &.active a {
        color: $light-green;
        border-color: $light-green;
      }

      a {
        position: relative;
        color: $green;
        transition: .4s;
        padding-bottom: 5px;
        border-bottom: 2px solid transparent;

        &:hover {
          color: $light-green;
          border-color: $light-green;
        }
      }
    }

    &__submenu {
      position: absolute;
      left: 0;
      opacity: 0;
      transition: .4s;
      padding: 20px;
      z-index: 99;
      max-height: inherit;
      pointer-events: none;
      min-width: 240px;
      max-width: 240px;
      margin-top: 15px;
      align-items: flex-start;

      #{$s}__nav-item {
        margin-bottom: 8px;
        text-align: left;

        a {
          border-bottom: 0;
        }
      }
    }

    

    &__has-child {
      &:hover {
        padding-bottom: 20px;

        #{$s}__submenu {
          position: absolute;
          left: 0;
          opacity: 1;
          pointer-events: all;
        }

        a {
          &::after {
            border-color: $light-green;
          }
        }
      }
    }

    &__button {
      width: auto;
      border-radius: 4px;
      padding: 10px 15px;
      font-weight: 400;
      transition: .4s;

      &:hover {
        background-color: rgba($green, .8);
      }
    }

    &__hamburguer {
      display: none;
    }
  }
}

@media(min-width: #{map-get($breakpoints, 'xl')}px) {
  .main-header {
    &__logo-area {
      max-width: 190px;
    }
  }
}